<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Onboard Approval"/>
        <v-spacer></v-spacer>
        <!-- Export Dialog with Button -->
        <v-dialog v-model="dialog_export" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('export')"
                class="mx-2" depressed color="primary" v-on="on" :disabled="!allowSearch">
                <v-icon left dark>mdi-upload</v-icon> Export
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">Export Onboards</span>
            </v-card-title>

            <v-card-text>
                <p>คุณต้องการ Export ข้อมูลตามที่ได้ทำการ filter ไว้ใช่หรือไม่</p>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog_export = false">ยกเลิก</v-btn>
              <v-btn color="blue darken-1" text @click="startExport">ตกลง</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
        
        <!-- Import Dialog with button -->
        <v-dialog v-model="dialog_import" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('import')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-download</v-icon> Import
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">Import Data</span>
            </v-card-title>
            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                          v-model="editedItem.import_file"
                          :rules="[validate_rules.required,validate_rules.filesize,validate_rules.filetype]"
                          accept="text/csv"
                          placeholder="Browse a csv file"
                          label="Topup List"
                      ></v-file-input>
                  </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeImport">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="submitImport">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar>  
      <v-divider></v-divider>
      <!-- Content -->
      <div class="content-wrap">
        <v-toolbar class="search-tools pa-0 mb-10 mt-5" flat style="height:100px">
          <v-col cols="1" sm="1" class="pl-0">
            <!-- Action Button -->
            <v-menu 
            offset-y
            v-model="actionDropdown">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  v-on="on"
                >
                  Actions<v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in actionsMenu"
                  :key="index"
                  @click="showBulkModal(item.action)"
                  :class="{ hassep: item.hassep }"
                >
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="11" sm="11">
            <v-row justify="end">
              <v-col cols="4" sm="4" class="py-2">
                <v-text-field
                  label="Search No., Name, Phone"
                  v-model="options.filter.keyword"
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  class="hidden-sm-and-down"
                  @keyup.native.enter="getDataFromApi"
                  clearable
                /> 
              </v-col>
              <v-col cols="4" sm="4" class="py-2">
                <v-select
                  :items="approveSteps"
                  label="Approve Step"
                  v-model="options.filter.approve_step"
                  item-value="id"
                  item-text="name"
                  hide-details
                  clearable=""
                ></v-select>
              </v-col>
              <v-col cols="3" sm="3" class="py-2">
                <v-combobox
                  :items="trainingOptions"
                  label="Training"
                  v-model="options.filter.training_date"
                  item-value="id"
                  item-text="value"
                  hide-details
                  clearable
                ></v-combobox>
              </v-col>
              <!-- ---------------------->
              <v-col cols="4" sm="4" class="py-2">
                  <v-select
                      v-model="options.filter.category_tag"
                      :items="categoriesArray"
                      label="Category Tags"
                      multiple
                      :single-line="true"
                      height="32px"
                      hide-details
                      >
                      <template v-slot:prepend-item>
                          <v-list-item
                          ripple
                          @click="toggle_cat_multi_select"
                          >
                          <v-list-item-action>
                              <v-icon :color="options.filter.category_tag.length > 0 ? 'indigo darken-4' : ''">{{ selectall_cat_icon }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                      </template>
                  </v-select>
              </v-col>
              <v-col cols="4" sm="4" class="py-2">
                  <v-select
                      v-model="options.filter.equipments"
                      :items="equipmentFilter"
                      item-text="text"
                      item-value="id"
                      label="Equipments"
                      multiple
                      hide-details
                      :single-line="true"
                      height="32px"
                      >
                      <template v-slot:prepend-item>
                          <v-list-item
                          ripple
                          @click="toggle_eq_multi_select"
                          >
                          <v-list-item-action>
                              <v-icon :color="options.filter.equipments.length > 0 ? 'indigo darken-4' : ''">{{ selectall_eq_icon }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                      </template>
                  </v-select>
              </v-col>
              <v-col cols="3" sm="3" class="py-2">
                <v-select
                  :items="depositStatus"
                  label="Deposit Status"
                  v-model="options.filter.deposit_status"
                  item-value="id"
                  item-text="text"
                  hide-details
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="4" sm="4">
                    <v-combobox
                      :items="allProvinces"
                      label="Province"
                      v-model="options.filter.province"
                      hide-details
                      @change="fetchZones()"
                      clearable
                    ></v-combobox>
                </v-col>
                <v-col cols="4" sm="4">
                    <v-combobox
                      :items="allZones"
                      label="District"
                      :search-input.sync="selectedZone"
                      v-model="options.filter.zone"
                      item-value="value"
                      item-text="text"
                      @click="ifProvSelected()"
                      hide-details
                      clearable
                    ></v-combobox>
              </v-col>
              <v-col cols="3" sm="3">
                  <v-btn class="mx-0" depressed small color="primary" @click="options.page = 1;getApproveDrivers(options);" :disabled="!allowSearch">
                      Search
                  </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="dataLisings"
          item-key="id"
          :options.sync="options"
          :server-items-length="totalResult"
          :loading="loadingDataTable"
          multi-sort
          :footer-props="{
            'items-per-page-options': [25, 100, 500, 1000]
          }"
          class="text-small"
          v-model="selectedList"
          show-select
        >
          <template #item.name_th="{value, item}">
              <router-link :to="`/approve_onboard/${item.id}/edit`">{{ value }}</router-link>
          </template>
          <template #item.province_id="{value}">
              {{ ProvincesArray[value] || '' }}
          </template>
          <template #item.employee_category_tag_names="{value}">
            <ul v-if="value && value.length > 0">
              <li v-for="tag in value" :key="tag">{{ tag }}</li>
            </ul>
          </template>
          <template #item.employee_training.training_schedule="{value}">
              <span>{{value ? moment(value.training_date).format('DD-MM-YYYY') : "---"}}<br/>
              ({{ value ? moment(value.training_time_from,'HH:mm:ss').format('HH:mm') : "---"}}-{{moment(value.training_time_to,'HH:mm:ss').format('HH:mm')}})</span>
          </template>
          <template #item.employee_approve_step.name="{item,value}">
            <v-badge color="pink" dot v-if="item.approve_step_id == 5 && item.latest_approve_log.comment != ''">
                  {{ value }}
            </v-badge>
            <span v-else>{{ value }}</span>
          </template>
          <template #item.deposit_status="{item}">
            <v-chip v-if="item.deposit_status == 1" color="green" dark x-small>Paid</v-chip>
            <v-chip v-else-if="item.deposit_status == 0" color="grey lighten-2" dark x-small>Not Paid</v-chip>
            <v-chip v-else-if="item.deposit_status == 2" color="red darken-4" dark x-small>Refund</v-chip>
          </template>
          <template #item.view_employee_equipment_summary="{value}">
              <ul class="my-2" v-if="Array.isArray(value.group_equipment_id)">
                <li v-for="(i,index) in value.group_equipment_id" :key="index">
                  {{ i | equipment_options(equipmentList.filter(item => item.id == i)) }}
                </li>
              </ul>
          </template>
          <template #item.createdAt="{value}">
              {{ value | datetime }}
          </template>
          <template #item.updatedAt="{value}">
              {{ value | datetime }}
          </template>
          <template #item.action="{item}">
                <v-btn class="ma-1" outlined color="primary" icon tile small>
                  <!-- <v-icon small @click="editItem(item)">mdi-pencil</v-icon> -->
                  <v-icon small @click="()=>$router.push(`/approve_onboard/${item.id}/edit`)">mdi-pencil</v-icon>
                </v-btn>
          </template>
        </v-data-table>
      </div>
      <div class="modal">
        <v-dialog v-model="bulkApproveModal" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ bulkModalTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="formbulk_valid" ref="formbulk">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <template>
                        <p v-if="bulkGoAction!='add_tags'">คุณต้องการ {{ bulkGoAction }} onboard {{ selectedList.length }} รายการ ใช่หรือไม่</p>
                        <p v-else>คุณต้องการเพิ่มคนขับ {{ selectedList.length }}  คน ไปยัง category tags ใช่หรือไม่</p>
                      </template>
                      <p v-if="bulkGoAction=='approve'"><strong>Note:</strong> ระบบจะทำการสร้าง Loginext account ในระหว่างนี้ <br/>
                                          สถานะ Approve Step ของคนชับจะแสดงเป็น 'LN Registering'</p>

                      <template v-if="bulkGoAction=='reject'">
                        <v-text-field v-model="reject_message"  label="กรุณาใส่เหตุผล" clearable></v-text-field>
                      </template>
                      <template v-if="bulkGoAction=='add_tags'">
                        <v-autocomplete
                          class="mt-5 tags_input"
                          :items="categoriesArray"
                          v-model="bulk_selected_category"
                          filled
                          :rules="[validate_rules.required]"
                          small-chips
                          :single-line="false"
                          deletable-chips
                          label="กรุณาเลือก Category Tags"
                          hide-selected
                          multiple
                          clearable
                        ></v-autocomplete>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModal">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="bulkAction(bulkGoAction)">Submit</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </div>
      <div class="modal">
        <v-dialog v-model="show_error_tmw" max-width="500px">
          <v-card>
            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">

                      <ul>
                          <li v-for="(log, index) in error_tmw" :key="index">
                              {{  log.data.message  }}
                          </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModal">Cancel</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'OnboardApproval',
  components: {
      pageHeading
  },
  data(){
    return {
      bulk_selected_category: null,
      dialog_export: false,
      dialog_import: false,
      editedItem: {
        id: -1,
        import_file: null,
        replace: true
      },
      defaultItem: {
        id: -1,
        import_file: null,
        replace: true
      },
      form_valid : false,
      formbulk_valid : false,
      validate_rules : {
        required: v => !!v || 'This field is required',
        filesize:  v => !v || v.size < 2000000 || 'Updload size should be less than 2 MB!',
        filetype:  v => {
          if(v){
            var ext = v.name.split('.').pop();
            return ["csv"].includes(ext) || 'Please upload .csv file only';
          } else {
            return 'This field is required'
          }
        }
      },
      selectedList : [],
      actionDropdown: false,
      actionsMenu : [
        { name : 'Approve', action : 'approve', hassep: false},
        { name : 'Reject', action : 'reject', hassep: false},
        { name : 'Add Driver to Tag', action : 'add_tags', hassep: true},
      ],
      approveSteps : [
        // { id: 1, name : 'Lead'},
        // { id: 2, name : 'Lead Rejected'},
        { id: 3, name : 'Lead Approved'},
        { id: 4, name : 'Onboard Rejected'},
        { id: 5, name : 'LN Registering'},
        // { id: 6, name : 'Onboard'},
        // { id: 7, name : '1 Trip'},
        // { id: 8, name : '10 Trips'},
        // { id: 9, name : 'Driver'},
      ],
      options: { 
        type: 'onboard',
        page: 1,
        itemsPerPage: 25,
        sortBy: ['updated_at'],
        sortDesc: [true],
        multiSort: true,
        mustSort: true,
        filter: {
          keyword: '',
          approve_step: null,
          training_date: undefined,
          province: null,
          zone: null,
          equipments:[],
          category_tag:[],
          deposit_status: null
        }
      },
      bulkApproveModal: false,
      bulkModalTitle: '',
      bulkModalMessage: '',
      bulkGoAction: null,
      is_reject: false,
      reject_message : '',
      show_error_tmw : false,
      error_tmw : []
    }
  },
  methods: {
    ...mapActions(['genCan','getProvinces','getZones','clearZones','approving','getApproveDrivers','getCategories',
                    'getTrainings','getEquipments','bulkApprove','bulkAddCategory','ImportEmployeeData','exportEmployees']),
    showBulkModal(type){
      this.reject_message = '';
      this.closeDropdown();
      if(this.selectedList.length > 0) {
          this.bulkApproveModal = true;
          switch (type){
            case 'reject':
              this.bulkModalTitle = 'Bulk reject';
            break;
            case 'approve':
              this.bulkModalTitle = 'Bulk approve';
            break
            case 'add_tags':
              this.bulkModalTitle = 'Add drivers to Category tags';
            break;
          }
          this.bulkGoAction = type;
      } else {
         alert('please select a driver...');
      }
    },
    async bulkAction (type) {
      if(this.formbulk_valid){
        // this.closeModal();
        var input = null;
        if(this.selectedList.length > 0) {
          if(type === 'add_tags'){
              
              input = {
                'page' : 'onboard',
                'category_tag' : this.bulk_selected_category,
                'employee_id' : _.map(this.selectedList,'id'),
              }

              this.bulkAddCategory(input);
              this.closeModal();

          } else {
              input = {
                'approveType' : type,
                'selectedList' : _.map(this.selectedList,'id'),
                'comment'  : this.reject_message
              }
              // call store service action
              let approve_result = await this.bulkApprove(input);
              let error_tmw = _.filter(approve_result, {is_error : true, is_tmw_error : true });
              this.closeModal();
              console.log("error_tmw", error_tmw);
              this.show_error_tmw = error_tmw.length > 0;
              this.error_tmw = error_tmw;
          }

          // clear selecttion
          this.selectedList = [];
        } else {
          alert('please select a driver...');
          this.closeModal();
        }
        
      }
    },
    closeDropdown(){
      this.actionDropdown = false
    },
    closeModal() {
        this.bulkApproveModal = false;
        this.bulk_selected_category = null;
        this.reject_message = '';
        this.$refs.formbulk.resetValidation();
        this.show_error_tmw = false;
    },
    getDataFromApi() {
        this.options.page = 1;
        this.getApproveDrivers(this.options);
    },
    toggle_eq_multi_select () {
      this.$nextTick(() => {
        if (this.selectedEqAll) {
          this.options.filter.equipments = []
        } else {
          this.options.filter.equipments = [];
          this.equipmentFilter.forEach(element => {
            this.options.filter.equipments.push(element.id);
          });
        }
      })
    },
    toggle_cat_multi_select () {
      this.$nextTick(() => {
        if (this.selectedCatAll) {
          this.options.filter.category_tag = []
        } else {
          this.options.filter.category_tag = [];
          this.categoriesArray.forEach(element => {
            this.options.filter.category_tag.push(element);
          });
        }
      })
    },
    ifProvSelected(){
      if(!this.options.filter.province)
        alert('Please select a province filter');
    },
    fetchZones() {
      this.options.filter.zone = null;
      if(this.options.filter.province){
        this.getZones(this.options.filter.province.value);
      } else {
        this.clearZones();
      }
    },
    closeImport() {
      this.dialog_import = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },
    submitImport() {
      if(this.form_valid){
        this.editedItem.replace = true;
        console.log('save',this.editedItem);
        this.ImportEmployeeData(this.editedItem);
        this.closeImport()
      }
    },
    startExport() {
      this.dialog_export = false; 
      this.exportEmployees(this.options);
    }
  },
  computed: {
    // ** Datable Headers
    ...mapGetters(['canDo','loadingDataTable','allApproveDrivers','ProvincesArray','allProvinces',
    'allZones','trainingOptions','equipmentList','equipmentFilter','depositStatus','categoriesArray', 'allowSearch']),
    headers(){
      return [
        {text: "Driver No.", value: 'employee_no',width: '120px'},
        {text: "Name", value: 'name_th',width: '120px'},
        // {text: "Email", value: 'email'},
        {text: "Phone No.", value: 'phone',width: '110px'},
        {text: "Province", value: 'province_id',width: '150px'},
        {text: "District", value: 'site_name',width: '120px'},
        {text: "Store", value: 'store_name', sortable: false},
        {text: "Category Tags", value: 'employee_category_tag_names',width: '150px'},
        {text: "Training Date", value: 'employee_training.training_schedule', sortable: false, width: '150px', align: 'center'},
        {text: "Approve Step", value: 'employee_approve_step.name',width: '130px'},
        {text: "Deposit Status", value: 'deposit_status',width: '130px', align: 'center'},
        {text: "Equipments", value: 'view_employee_equipment_summary', width: '150px', align: 'left', sortable:false},
        {text: "Register At", value: 'createdAt',width: '130px'},
        {text: "Last Update", value: 'updatedAt',width: '130px'},
        {text: "Action", value: 'action', sortable: false},
      ]
    },
    selectedZone: {
        get: function () {
          return this.options.filter.zone ? this.options.filter.zone.text : null;
        },
        set: function () {
          return this.options.filter.zone ? this.options.filter.zone.text : null;
        }
    },
    dataLisings() { 
        var res = this.allApproveDrivers ? this.allApproveDrivers.data : [];
        return  res;
    },
    totalResult(){
            return (this.allApproveDrivers ? this.allApproveDrivers.count : null) || 0;
        },
    // Manage filter multi select for Category_tags
    selectedCatAll () {
        return this.categoriesArray ? (this.options.filter.category_tag.length === this.categoriesArray.length) : false;
    },
    selectedCatSome () {
        return this.options.filter.category_tag.length > 0 && !this.selectedCatAll;
    },
    selectall_cat_icon () {
        if (this.selectedCatAll) return 'mdi-close-box'
        if (this.selectedCatSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    },
    // Manage filter multi select for Equipments
    selectedEqAll () {
        return this.equipmentFilter ? (this.options.filter.equipments.length === this.equipmentFilter.length) : false;
    },
    selectedEqSome () {
        return this.options.filter.equipments.length > 0 && !this.selectedEqAll;
    },
    selectall_eq_icon () {
        if (this.selectedEqAll) return 'mdi-close-box'
        if (this.selectedEqSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    },
  },
  filters:{
    equipment_options(value, eq){
        return  eq[0] ? eq[0].name : '';
    }
  },
  watch: {
    actionDropdown (val){
      val || this.closeDropdown()
    },
    'options.sortBy': function (newVal, oldVal){
          //to work with changes in someOtherProp
          if(newVal){
            this.getApproveDrivers(this.options);
          }
    },
    selectedList() {
      var selected_id  = _.map(this.selectedList,'id');
    },
  },
  created() {
    this.genCan();
    this.getProvinces();
    this.approving('onboard');
    this.getTrainings('all');
    this.getEquipments();
    this.getApproveDrivers(this.options);
    if(!this.categoriesArray){
      this.getCategories();
    }
  }
};
</script>